export const m = {
  //菜单栏
  cop: '广东梵吉尼科技有限公司',
  product :'产品',
  theBrand :'关于我们',
  contactUs :'服务与支持',
  languageText:'中文',
  shop: '商城',
  enText:"English",
  chText:"中文",
  jpText:"日本語",
  aboutFungene: "关于梵吉尼",
  story: "品牌故事",
  home: "首页",
  mine: "我的",
  xf:"智能香氛机",
  //产品
  productDes: '梵吉尼自动感应洗手器',
  
  A8productDes: '梵吉尼智能香氛机',

  //品牌故事
  brand:'梵吉尼，中国新兴生活方式品牌，坚持可持续设计理念，将原创、科技与环保相结合，给更懂生活的你们提供创造性的、更具品质感的、更优质的生活体验。梵吉尼，倡导“满足想象的生活美学”，我们希望通过改变产品的设计和交互，帮助您获得更优质的生活、更舒心的体验。',
  brand01:'梵吉尼，中国新兴生活方式品牌，坚持可持续设计理念，将原',
  brand02:'创、科技与环保相结合，给更懂生活的你们提供创造性的、更',
  brand03:'具品质感的、更优质的生活体验。梵吉尼，倡导“满足想象的',
  brand04:'生活美学”，我们希望通过改变产品的设计和交互，帮助您获',
  brand05:'得更优质的生活、更舒心的体验。',

   //联系我们
   contact: '联系我们',
   company: '广东梵吉尼科技有限公司',
   address: '公司地址：佛山市顺德区大良街道新滘社区凤翔路41号顺德创意产业园G栋',
   holine: '客服热线：4001331518',
   salesHotline: '销售热线：David +86 13921060980, Sandra +86 13060784883',
   email: '官方邮箱：service@fungene.cc',
   scantext0: '即刻扫码查看梵吉尼小程序',
   scantext1: '了解更多动态',

    //产品
    mainH5:'智能洗手器',
    // mainMarket:'即将上市 敬请期待',
          mainBook:'购买 >',

    mainTextW0:'所有的改变都是一种深思熟虑过后的奇迹',
    mainTextW1:'这是一台智能洗手器。它拥有极致的性能、独创',
    mainTextW2:'的设计，以及卓越的制造工艺，为您筑起一道坚固',
    mainTextW3:'的健康防线，让您远离细菌、病毒的威胁。',

    mainTextM01:'所有的改变都是一种深思熟虑过后的奇迹',
    mainTextM02:'这是一台智能洗手器。它拥有极致的性能、独创的设计，以及卓越的制造工艺，为您筑起一道坚固的健康防线，让您远离细菌、病毒的威胁。',

    index01:'超长待机',
    index02:'90天持久续航',
    index03:'友好交互',
    index04:'有趣的表情界面',
    index05:'拿起感应',
    index06:'防止误出液',
    index07:'静音电机',
    index08:'精选电机耐用静音',
    index09:'靠近唤醒',
    index10:'满心满眼都是你',
    index11:'金属机身',
    index12:'更有质感的体验',
    index13:'缺液提醒',
    index14:'液量不足时提醒更换',
    index15:'匠心设计',
    index16:'匠心设计优雅致趣',

    acme01:'不管做什么',
    acme02:'都要做到极致',
    acme03:'我们一直在努力',
    acme04:'只为穿过岁月与你并肩同行',
    acme05:'简单的线条、内敛的设计、中性的色彩，呈现出一种干净完美的形象',
    acme06:'精致的电镀工艺带来优雅的视觉效果和细腻舒适的触感',
    acme07:'如艺术品般精雕细琢的美丽，兼具外观和内涵。',
    acme08:'简单的线条、内敛的设计、中性的色彩，呈现出一种干净完美的形象,精致的电镀工艺带来优雅的视觉效果和细腻舒适的触感,如艺术品般精雕细琢的美丽，兼具外观和内涵。',

    intelligent01:'智能',
    intelligent02:'自动感应出液',
    intelligent03:'智能生活触手可及',
    intelligent04:'无需接触，只需伸伸手，H5保护您远离细菌、病毒的威胁',
    intelligent05:'和您一起共筑健康防线',
    intelligent06:'无需接触',
    intelligent07:'只需伸伸手',
    intelligent08:'H5保护您远离细菌、病毒的威胁',

    standby01:'超长待机',
    standby02:'持久续航90天',
    standby03:'5000mAh大容量电池+独创电池管理方案',
    standby04:'续航最长达2万次，不用频繁充电',
    standby05:'5000mAh大容量电池',
    standby06:'独创电池管理方案',
    standby07:'续航最长达2万次',
    standby08:'不用频繁充电',
    standby09:'（*数据来自梵吉尼实验室，按每天30次计算）',

    close01:'靠近感应',
    close02:'请靠近一点',
    close03:'让我将你感受的更加细致',
    close04:'当你靠近我的时候，我才充满活力；当你靠近我的时候，你会发现我满心满眼都欢喜，都是',
    close05:'因为你。来吧，靠近我，让我带你用纯净的手去抚摸孩子的微笑、爱人的嘴角，共同感受、守护',
    close06:'这个世界的温柔和美好。',
    close07:'当你靠近我的时候，我才充满活力；当你靠近我的时候，你会发现我满心满眼都欢喜，都是因为你。来吧，靠近我，让我带你用纯净的手去抚摸孩子的微笑、爱人的嘴角，共同感受、守护这个世界的温柔和美好。',
  
    interaction01:'感应',
    interaction02:'摇晃异常感应',
    interaction03:'内置感应芯片，拿起或摇晃时灵敏感应，',
    interaction04:'有效防止误出液',
    interaction05:'内置感应芯片',
    interaction06:'精准判断缺液',
    interaction07:'内置感应芯片',
    interaction08:'拿起或摇晃时灵敏感应',
    interaction09:'缺液感应',
    interaction10:'机器状态尽在掌握',

    color01:'色彩',
    color02:'优雅有型',
    color03:'足够出色',
    color04:'沉稳如夜色般的深邃黑，典雅如深秋似的古铜色，亦可定制属于您的专属色。',
    color05:'深邃黑',
    color06:'古铜色',
    color07:'定制色',

    inside01:'内部',
    inside02:'细微之处见风范',
    inside03:'毫厘之间定乾坤',
    inside04:'细节是产品最有力的表现形式，我们在细微之处不断求索，旨在为您带来更为优质的体验。',

    listen01:'请听我说',
    listen02:'H5诞生背后的故事',
    listen03:'好的设计需要将人生的经历融化到自己的创意里。H5的设计师是一个屡获红点、iF大奖的80后奶爸设计师。疫情到来时，他非常焦虑，因为三岁的儿子正处于叛逆、难缠的阶段，哪儿脏摸哪儿，让干嘛偏不干嘛......简单的洗手都变成了难搞的事，讲道理、威逼利诱都不管用，最后，往往在孩子的哭声和父母的不耐烦中才能洗完手。因此，他想设计一个成人、孩子都不抵触的洗手方案。',
    listen04:'就这样，H5诞生了!以用一颗童真的心，创造一个奇妙的生活意境。简洁而更具艺术化的设计语言，结合有趣灵动的交互方式，让H5不再单单是一台洗手器，更是真实世界中想象力具像化的载体，它像一位老友，三百六十五天日日陪伴，为您的健康保驾护航。',

    parameters01:'基本参数',
    parameters02:'产品型号:H5',
    parameters03:'额定电压:DC 4V',
    parameters04:'额定功率:2.5W',
    parameters05:'主要材料:金属、ABS+PC',
    parameters06:'执行标准:GB4706.1',
  
  //底栏
  footText: 'Copyright © 2022 梵吉尼 ',
  record:'粤ICP备2020126461号',

  //官网第二版
  video: '视频教程',
  vip:'梵吉尼尊享服务',
  oil: '精油',
  hometop: '探索科技美学新边界',
  home1: '品质生活',
  home2: '艺术美学',
  home3: '匠心工艺',
  home4: '高端智能',
  home5: '经得住时间洗礼的匠艺奢品。',
  home6: '自主研发金属雾化头',
  home7: '有效防止精油腐蚀',
  home8: '搭配二流体空气雾化技术',
  home9: '达到细如纳米的雾化效果',
  home10: '轻松覆盖1500m³的室内空间',

  home11: '机身采用优质铝材',
  home12:'精选全球稀缺优质水晶打造底座',
  home13:'与无极调光模式下的灯光交相辉映',

  home14: '新锐设计团队匠心打造',
  home15: '兼具优良性能和品质',
  home16: '出色的扩香技术',
  home17: '芬芳飘逸,提升室内氛围、缓解情绪',
  home18: '带来高品质生活',

  home19: '智能操控,轻松连接现代生活',
  home20: '体验更人性化的多样需求',

  middle1: '生活·空间·科技·美',
  middle2: '简单明了、不留冗杂',
  middle3: '在“美学”与“功能”这座天平的两端',
  middle4: '窥见对立与统一',
  middle5: '诠释极简艺术审美',
  middle6: '带来美好生活体验',

  art1: '艺术展厅,办公室,图书馆,休闲空间，',
  art2: '商场,专卖店,工作室,娱乐场所，',
  art3: '客厅,卧室,厨房,卫生间(...)',

aboutUs: '关于我们',
aboutDes:'有趣的基因」作为FUNGENE的品牌内核,驱动着我们不断突破创新和美学的界限,创造更具生命力的产品',

a1: 'FUNGENE梵吉尼,诞生于2015年的高端智能科技电器品牌。',
a2: '「有趣的基因」作为品牌内核,驱动着我们不断突破创新和美学的界限，',
a3: '创造更具生命力的、经得住时间洗礼的匠心艺术品。',
a4: 'FUNGENE 梵吉尼始终坚持「以人为本」理念,采用「极简」设计风格，',
a5: '以「匠心精神」作为品牌成长路上的坚守,在设计之路上不断深入挖掘新的设计语言，',
a6: '在工艺打磨上锲而不舍地钻研品质与细节,以高品质产品表达对消费者的真诚。',

a7: '环保、再生、可持续..在设计、生产、材料、工艺、创新的每一个进程中，',
a8: 'FUNGENE都将人与自然关系的思考贯穿始终,极力塑造人与自然的和谐状态。',

a9: '科技在创新、功能在迭代,时代日益变迁,不变的是，',
a10: '「“人”始终是我们永恒的关注对象,数字化自主研发、交互式设计、多功能应用....',
a11: '多功能应用....本质依然是探讨消费者需求,为消费者提供理想生活的体验。',

a12: '实现“极简美”的过程并不极简,从传统材料中挖掘潜在的可能性，',
a13: '在大自然间汲取优质稀缺原材料,经一丝不苟的工艺打磨,不断钻研细节与品质，',
a14: '才能锻造出经久耐用、持久不衰的匠艺奢品。',


bDes1: '江苏',
bDes2: '江苏常州市',
bDes3: '溧阳市天目湖工业园区建业路2号',
bDes4: 'FUNGENE梵吉尼|总部',

bDes5: '广东',
bDes6: '广东省佛山市顺德区',
bDes7: '大良街道新滘社区凤翔路41号（顺德创意产业园G栋）',
bDes8: '研发中心|营销|市场',

bDes9: '上海',
bDes10: '上海市长宁区',
bDes11: '延安西路1538号（同创717创业园5号楼）',
bDes12: '研发中心|营销|市场',

cDes1: '售后服务',
cDes2: 'FUNGENE十分重视客户的体验感和满意度，',
cDes3: '为此,我们打造了一支经验丰富、专业素质高、热情友好的团队，',
cDes4: '并将以诚信、耐心和细致的态度来为客户提供高质量服务，',
cDes5: '以确保客户拥有舒适的购物体验及产品使用体验。',
cDes6: '售后服务热线:',
cDes7: '邮箱:',

dDes1: '社交平台',
dDes2: '我们诚挚地邀请您关注FUNGENE社交平台账号,和我们进行互动，',
dDes3: '让我们能在第一时间了解您的需求和反馈,以督促我们不断提升产品和服务质量。',
dDes4: '我们会在社交账号定期发布品牌和产品的最新信息.',
dDes5: '也会分享我们的故事、活动等有趣的内容，',
dDes6: '您不仅能够快速获取品牌的最新动态,还可以参与到我们的互动活动中，',
dDes7: '获得丰富的奖品和福利。',
dDes8: '小红书官方账号/微信公众号:FUNGENE梵吉尼 天猫旗舰店:FUNGENE梵吉尼旗舰店',

dDes9: 'APP/小程序',
dDes10: '可在APP STORE及各大应用程式商城中搜索“梵吉尼”下载APP、',
dDes11: '亦可通过微信小程序搜索“梵吉尼智控”进行使用',
dDes12: '让空间智慧 让生活更优雅',




// A8
a8Title: 'A8智能香氛机',
color: '颜色',
buy: '购买',
a8T: '品味A8',
a8I: '品质A8',
a8H: '荣耀A8',
a8TDes: 'FUNGENEA8香氛机是一款专注于高端场景的智能香氛机,FUNGENE A8突破常规的设计,将卓越的性能、 奢华的品质、出色的扩香技术体现得淋漓尽致,让居住空间获得前所未有的质感升级。',
a8IDes: '我们相信，现代家居生活与创新智能紧密相连,FUNGENE梵吉尼自主研发智能生态系统,通过app或小程序进行连接，让您随心随地根据个人需求调整设置,轻松实现多项智能操控,享受愉悦的美好生活体验。',
a8HDes: '简约的艺术表达,一体化的流畅线条,利落而干净的轮廓，配以匠心工艺打磨,铸就了FUNGENE A8,并获得2023iF设计奖。',
a8Type: '类别：智能电器',

a8DeviceDes1: '简约的美学表达与顶级制造工艺',
a8DeviceDes2: '结合出色的扩香技术',
a8DeviceDes3: '呈现出一场无与伦比的香氛艺术',

a8SquDes1: '二流体雾化技术',
a8SquDes2: '搭配纳米技术级雾化效果',
a8SquDes3: '满屋弥香',
a8SquDes4: '臻享奢华的优雅香氛体验',

appDes1:'以人为本的',
appDes2:'创新智能设计。',
appDes3:'通过通过梵吉尼app、小程序,轻松实现各项远程操作、智能的浓度调节，',
appDes4:'让精油能够以最佳状态呈现在空间中;',
appDes5:'通过梵吉尼的创新智能生态系统,FUNGENEA8得以最大化展现它的便捷与性能。',

appDes6:'磁吸结构进气栅格',
appDes7:'简洁优雅。',
appDes8:'可清洗式过滤网搭配钻石车削进气栅格，安装便捷；',
appDes9:'阻挡空气中的有害分子的介入，展现非凡的功效',


a8Scene1: '独家静音气泵',
a8Scene2: '守护每一刻的静谧时光',
a8Scene3: '重构电器美学',
a8Scene4: '感知格调生活',
a8Scene5: '香氛体验',


// H5
h5Title: 'H5智能洗手器',
h5Price: '￥699起',
h5T: '品味H5',
h5I: '品质H5',
h5H: '荣誉H5',
h5TDes: 'FUNGENE H5是一款在创新、品质与艺术美学的多重标准下诞生的智能自动皂液器,拥有行业首创智能交互微表情,让隐藏在角落的小物件,也能焕发温暖的品质生活气息。',
h5IDes: '机身精选优质铝材,经抛光、氧化、电镀等多重工艺悉心打磨,铸就了FUNGENE H5近乎艺术品的质感',
h5HDes: '灵巧的交互设计,极简的美学风格,美轮美负的光彩与色彩,令FUNGENE H5荣获2022 iF设计奖。',

h5Des1 : '匠心凝铸品质艺术品',
h5Des2 : '尊享别致',
h5Des3 : '创新洗手体验',

h5DeviceDes1: '无可挑剔的性能',
h5DeviceDes2: '匀速直流电机 持久耐用',
h5DeviceDes3: '低噪安全，转速稳定，更加持久耐用',
h5DeviceDes4: '经过多次的实验室验证',
h5DeviceDes5: '可正常使用超过100000次',

h5AppDes1: '工业级芯片 近场雷达感应',
h5AppDes2: '低功耗 智能体验。',
h5AppDes3: '行业首创微表情式交互体验,智能调节档位;当人靠近时机器自动识别唤醒，',
h5AppDes4: '无人时自动待机,节能环保,让洗手变得更加有趣。',

h5AppDes5:'创新自动清洁',
h5AppDes6:'清楚残余',
h5AppDes7:'有效清洁管道和瓶身内残留；大口径大容量，',
h5AppDes8:'换液更方便彻底。',

h5ColorDes1: '幻彩多变',
h5ColorDes2: '个性化定制',
h5ColorDes3: '重构电器美学',
h5ColorDes4: '感知格调生活',
h5ColorDes5: '场景体验',

// S5
s5Title: 'S5智能香氛机',
s5Price: '¥ 2180',
s5T: '品味S5',
s5I: '智能S5',
s5P: '品质S5',
s5TDes: 'FUNGENE S5智能香氛机,是极简美学的诠释者,是精油的绝佳伴侣,精致小巧的机身让FUNGENE S5成为一款更适合在桌面、房间等小型空间使用的香氛机,为空间增加艺术色影,感知全新的香氛体验。',
s5IDes: '我们相信,现代家居生活与创新智能紧密相连,FUNGENE梵言尼自主研发智能生态系统,通过app或小程序进行连接,让您随心随地根据个人需求调整设置,轻松实现多项智能操控,亨受愉说的美好生活体验。',
s5PDes: 'FUNGENE S5融合美学与性能,结合出色的扩香技术,将扩香的效果发挥至极致,是空间艺术香氛的尚达.',


s5DeviceDes1: '全机身采用优质铝材',
s5DeviceDes2: '用心匠造精湛工艺',
s5DeviceDes3: '以简胜繁',
s5DeviceDes4: '丰富的色系',
s5DeviceDes5: '为不同空间的搭配提供选择',


s5SquarDes1: '纳米级雾化 细腻入微',
s5SquarDes2: '自定义浓度',
s5SquarDes3: '寻找属于自己的嗅觉舒适度',
s5SquarDes4: '感受身心深层次的放松与愉悦',

s5APPDes1: '蓝牙无线双模式连接管理',
s5APPDes2: '一键轻松搞定。',
s5APPDes3: '下载梵吉尼APP，一键管理智能设备，即可远程控制扩香器；自定义场景和模式',
s5APPDes4: '实时查看精油消耗情况',

s5APPDes5: '航空级铝合金雾化腔',
s5APPDes6: '抗氧化耐腐蚀',
s5APPDes7: '细节决定品质，选用优质铝材打造',
s5APPDes8: '卓越性能',
s5APPDes9: '开启全新智能香氛体验',
s5APPDes10: '重构电器美学',
s5APPDes11: '感知格调生活',

// 精油
oilTitle: 'FUNGENE精油',
oilSmell: '香气故事',
oilDiao: '香调',
oilSX: '属性',
oilSL: '系列',
oilType: '类别：香氛',

oilDes1: '是大自然的慷慨馈赠，',
oilDes2: '赋予了这片独特的香氛世界',
oilDes3: '优雅、冷冽、静谧、炽热、自由',
oilDes4: '用感官感知时间的绵长与美好',
oilDes5: '心灵与呼吸的交织,传达生命的力量。',

oilSmellDes1: '闻得到大自然气息',
oilSmellDes2: '香氛。',

oilSmellDes3: '恍然如一缕晨光,于竹林山水间穿透迷蒙雾霭',
oilSmellDes4: '一朵盛开的茶花,预见又一个烷紫嫣红的春来到',
oilSmellDes5: '1亿5千万年前的一座冰川消融,亘古的风传来它的气息',
oilSmellDes6: '书页不言不语,却将古老的寓言娓娓道来',
oilSmellDes7: '空气在热浪中凝结,徒手碾碎一颗青涩的橘子,烈日熄灭',
oilSmellDes8: '穿越八荒的风，将草木的气息遗落在野',
oilSmellDes9: '层叠的密林吞下飞鸟，圣洁的秘密不露踪影',
oilSmellDes10: '湖畔的微风不说话,搅动一池清梦',
oilSmellDes11: '“咔嚓”,是落叶寂寞的低语,是秋日诗篇的注脚',
oilSmellDes12: '围炉而坐，岁月老人不忍驻足,此刻隽永',


// 关于我们
about1: '梵吉尼,创立于2015年,是一家专注于高场景的科技礼品公司。我们专注于营造美好的生活方式,通过我们的产品，让您的日常生活变得更加精致和有意义。梵吉尼,倡导“满足想象的生活美学”,我们希望通过改变产品的设计和交豆,帮助您获得更优质的生活、更舒心的体验。我们致力于将最新的科技成果用艺术化的手法融入到每一件产品中,确保每一件产品都是市场上独一无二的存在。',
about2: '我们的产品不仅仅是物品，更是传递情感、表达个性的媒介。我们的产品设计团队由一群富有创遗力的设计师和工程师想成,目前江苏源阳总部、上海&佛山分部共七十多名员工,具备完整的组织架构,具备优秀的研发团队,公司产品一经上市既取得良好的口碑,销重节节攀升。',
about3: '公司柔承"创新驱动、设计美学、追求卓越、精盐求精"的价值观,打造一支精英团队，并期待德才兼备的你成为我们的小伙伴,一起拼搏，共赢未来!',

about4: '愿景',
about5: '我们专注营造美好的生活方式，通记我们的产品，\n让您的日常生洁变得更加精致和有意义',
about6: '品牌理念',
about7: '我们致力于将最新的科技成果用艺术化的手法融入到每一件产品中，确保每一件产品都是市场上独一无二的存在，我们的产品不仅是物品们更是传递情感，表达个性的媒介',
about8: '价值观',
about9: '用户至上 创新为要 协力共进 精益求精',
about10: '价 值 观',
about11: '创新驱动 设计美学 追求卓越 精益求精',
about12: '技术要素',
about13: '稳定 易用 革新',


// story
story1: '我们用隽永的设计手法和优良的工艺，',
story2: '给您的生活留下惊喜时刻。',
story3: '梵吉尼致力于优良的交互，精湛的工艺，有温度的科技',
story4: '在这个快时尚的时代背景下',
story5: '用心打磨每一次细节，提升空间品味。',
story6: '我们希望在每一个品质空间，都有梵吉尼。',


// video
videoDes1: '开箱视频',
videoDes2: 'A8开箱视频',
videoDes3: 'H5开箱视频',
videoDes4: 'S5开箱视频',

videoDes5: '宣传视频',
videoDes6: 'A8宣传视频',
videoDes7: 'H5宣传视频',
videoDes8: 'S5宣传视频',

videoDes9: '教程视频',
videoDes10: 'H5更改液量',
videoDes11: 'H5清洗模式',
videoDes12: 'H5表情开关',
videoDes13: 'H5更换液体',

noBatter:'无电香薰机',
l6: 'L6暗香仪',
media: '媒体中心',
fun: '梵 吉 尼',
funTitle: '满足想象的生活美学'


































  }